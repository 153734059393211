export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addMirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add OS Mirror"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "conflict": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conflict with existing one."])},
        "provision_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requires one server, and at least 200G disk space."])},
        "existing_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you already have a existing OS mirror source that is available to all the machines used to install K8S."])},
        "canCreateItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You  can input a mirror url that does not exist in the list."])},
        "ubuntu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubuntu apt mirror"])},
        "centos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centos yum repo"])},
        "docker_ubuntu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubuntu apt mirror for docker"])},
        "docker_centos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centos yum repo for docker"])},
        "os_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS Type"])},
        "mirror_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Type"])},
        "mirror_type_os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS source"])},
        "mirror_type_os_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS Source"])},
        "mirror_type_docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker source"])},
        "mirror_type_docker_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contains installation packages for docker-ce / docker-cli / containerd."])}
      },
      "zh": {
        "addMirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 OS 软件源"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "conflict": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["与已有的重复 ", _interpolate(_named("name"))])},
        "provision_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要您提供一台机器，包含至少 200G 磁盘空间"])},
        "existing_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您已经有一个安装集群所用机器都可以访问到的 OS 软件源，可以直接在此处输入该软件源的访问地址。"])},
        "canCreateItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在此直接输入下拉列表中没有的条目"])},
        "ubuntu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubuntu apt mirror"])},
        "centos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centos yum repo"])},
        "docker_ubuntu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker 的 Ubuntu apt mirror"])},
        "docker_centos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker 的 Centos yum repo"])},
        "os_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统"])},
        "mirror_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["软件源类型"])},
        "mirror_type_os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统软件源"])},
        "mirror_type_os_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统提供的软件源"])},
        "mirror_type_docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["docker 软件源"])},
        "mirror_type_docker_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装 docker-ce / docker-cli / containerd 等软件包的源"])}
      }
    }
  })
}
