export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "titleRepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS mirrors"])},
        "repoDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You muse provide a mirror address (e.g. CentOS yum mirror, Ubuntu apt mirror) that all the nodes in your mirror could access."])},
        "repoDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usually, a enterprise has its own os mirror, if not, Kuboard alos provide a wizard, so that you can create an os mirror quickly."])},
        "addMirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add OS Mirror"])},
        "confirmToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is going to delete OS Mirror info, but OS Mirror server is not affected here."])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provision Method"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS"])},
        "mirror_type_os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS source"])},
        "mirror_type_docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker source"])}
      },
      "zh": {
        "titleRepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS 软件源"])},
        "repoDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须定义您集群机器可以访问的操作系统软件地址（例如 CentOS 的 yum 源、Ubuntu 的 apt 源等）以供使用"])},
        "repoDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常企业都有自己的常用操作系统的本地软件仓库，Kuboard 提供向导，帮助您快速设置一个操作系统软件源"])},
        "addMirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加 OS 软件源"])},
        "confirmToDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此操作将删除软件仓库信息，软件仓库本身并不受此影响"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类 型"])},
        "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供方式"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状 态"])},
        "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统"])},
        "mirror_type_os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统软件源"])},
        "mirror_type_docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["docker 软件源"])}
      }
    }
  })
}
